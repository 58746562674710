import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NavBar from '../components/NavBar';
import { getTextForStatus } from '../model/UserProfile';
import { Spinner } from 'react-bootstrap-v5';
import './Report.css';

export default function Report() {
    const [data, setData] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Generate report timestamp once
    const reportDate = new Date();
    const reportTitle = `Clients Matches Report - ${reportDate.toDateString()} ${reportDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;

    useEffect(() => {
        // Set the page title on load
        document.title = reportTitle;

        const token = localStorage.getItem("token");
        if (!token) {
            console.log("No token found. Redirecting to login page");
            navigate("/login", { replace: true });
        }

        const headers = { Authorization: `Bearer ${token}` };
        const reportUrl = `${process.env.REACT_APP_API_URL}/clientmatchesreport`;

        axios.get(reportUrl, { headers })
            .then(res => setData(groupData(res.data.clients)))
            .catch(err => setError(err))
            .finally(() => setLoading(false));
    }, [navigate, reportTitle]);

    const handlePrint = () => {
        window.print();
    };

    // Function to group data by name_string
    const groupData = (clients) => {
        const grouped = {};

        clients.forEach(client => {
            const key = client.name_string;

            if (!grouped[key]) {
                grouped[key] = { main: client, duplicates: [] };
            } else {
                grouped[key].duplicates.push(client);
            }
        });

        return Object.values(grouped);
    };

    // Toggle expand/collapse state
    const toggleExpand = (clientName) => {
        setExpandedRows(prev => ({
            ...prev,
            [clientName]: !prev[clientName],
        }));
    };

    return (
        <div className='container'>
            <NavBar />
            <div className="report-header">
                <h3 className="heading">{reportTitle}</h3>
                <button className="print-btn" onClick={handlePrint}>Print Report</button>
            </div>

            {loading ? (
                <Spinner animation="border" role="status" className="spinner">
                    <span className="sr-only"></span>
                </Spinner>
            ) : (
                <table className="table table-striped table-bordered table-hover">
                    <thead className="table-dark">
                        <tr>
                            <th style={{textAlign: 'left'}}>Client</th>
                            <th>Type</th>
                            <th>Gender</th>
                            <th>Age</th>
                            <th>Membership</th>
                            <th>LastDate</th>
                            <th>LastOutcome</th>
                            <th>MatchDate</th>
                            <th>Scheduled</th>
                            <th>Accepted</th>
                            <th>Rejected</th>
                            <th>Others</th>
                            <th>Coaching</th>
                            <th>...</th> {/* Empty for Expand button column include in print */}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(({ main, duplicates }) => (
                            <React.Fragment key={main.id}>
                                {/* Main Row */}
                                <tr>
                                    <td style={{textAlign: 'left'}}>
                                        <a target="_blank" rel="noreferrer" href={`https://app.datehighflyers.com/admin/viewprofile/${main.id}`}>
                                            {main.name_string} {duplicates.length > 0 ? `(${duplicates.length + 1})` : ""}
                                        </a>
                                    </td>
                                    <td>{getTextForStatus(main.role_id)}</td>
                                    <td>{main.gender === 9500 ? 'F' : main.gender === 9501 ? 'M' : '-'}</td>
                                    <td>{main.age}</td>
                                    <td>{main.endData}</td>
                                    <td>{main.lastDate}</td>
                                    <td>{main.coordinator_calls_outcome}</td>
                                    <td>{main.ccDate}</td>
                                    <td>{main.ccDateScheduledOn}</td>
                                    <td>{main.accepted}</td>
                                    <td>{main.rejected}</td>
                                    <td>{main.neitherAcceptedNorRejected}</td>
                                    <td>{main.coachings}</td>
                                    <td className="expand-btn-container">
                                        {/* Show the button only if rows are collapsed */}
                                        {duplicates.length > 0 && !expandedRows[main.name_string] && (
                                            <button
                                                className="expand-btn"
                                                onClick={() => toggleExpand(main.name_string)}
                                            >
                                                ⬇️
                                            </button>
                                        )}
                                    </td>
                                </tr>

                                {/* Expanded Rows */}
                                {expandedRows[main.name_string] &&
                                    duplicates.map((client) => (
                                        <tr key={client.id} className="expanded-row">
                                            <td>
                                                <a target="_blank" rel="noreferrer" href={`https://app.datehighflyers.com/admin/viewprofile/${client.id}`}>
                                                    {client.name_string}
                                                </a>
                                            </td>
                                            <td>{getTextForStatus(client.role_id)}</td>
                                            <td>{client.gender === 9500 ? 'F' : client.gender === 9501 ? 'M' : '-'}</td>
                                            <td>{client.age}</td>
                                            <td>{client.endData}</td>
                                            <td>{client.lastDate}</td>
                                            <td>{client.coordinator_calls_outcome}</td>
                                            <td>{client.ccDate}</td>
                                            <td>{client.ccDateScheduledOn}</td>
                                            <td>{client.accepted}</td>
                                            <td>{client.rejected}</td>
                                            <td>{client.neitherAcceptedNorRejected}</td>
                                            <td>{client.coachings}</td>
                                            <td className="expand-btn-container">
                                                {/* Show the collapse button only when rows are expanded */}
                                                {duplicates.length > 0 && expandedRows[main.name_string] && (
                                                    <button
                                                        className="expand-btn"
                                                        onClick={() => toggleExpand(main.name_string)}
                                                    >
                                                        ⬆️
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            )}

            {error && <div className='error'>{error.message}</div>}
        </div>
    );
}
