import { Navbar, Nav } from "react-bootstrap-v5";

const NavBar = () => {
  return (
    <Navbar bg="light" expand="lg" className="mynav">
      <Navbar.Brand href="#home">DHF Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/websiteleads">Website leads</Nav.Link>
          <Nav.Link href="/infocallleads">Info Call leads</Nav.Link>
          <Nav.Link href="/search">Clients</Nav.Link>
          <Nav.Link href="/report">Report</Nav.Link>
          <Nav.Link href="/dashboard">Dashboard</Nav.Link>
          <Nav.Link href="/logout">Logout</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default NavBar;
